.About_header {
  display: flex;
  justify-content: space-between;
  padding: 1rem 6vw;
}
.About_header_right img {
  width: 300px;
  border-radius: 30px;
}
.About_header_title {
  font-size: 2rem;
  text-align: center;
  margin: 20px 0;
  opacity: 0.4;
  font-weight: 100;
}
.About_header_left {
  margin: 0 2em;
}
.About_header_left p {
  font-size: 1.3em;
  font-weight: 300;
  text-align: justify;
}
.About_header_left h1 {
  font-weight: 400;
  margin-bottom: 0.4em;
  text-transform: capitalize;
}
.ClassCont {
  position: relative;
}
.ClassCont .ClassImg {
  width: 100%;
  aspect-ratio: 16 / 7;
  /* filter: grayscale(0.9) brightness(40%); */
}
.ClassCont span {
  z-index: 10;
}
.ClassCont .IconsCont {
  position: absolute;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.ClassCont .IconsCont .FaRight,
.ClassCont .IconsCont .FaLeft {
  cursor: pointer;
  background: #d8d7c58f;
  backdrop-filter: blur(3px);
  padding: 5px 10px;
  border-radius: 100%;
}
.ClassCont .IconsCont .IconsText {
  text-align: center;
  color: var(--secondary);
  color: var(--purple);

  font-size: 3vw;
  background: #d8d7c58f;
  backdrop-filter: blur(3px);
  clip-path: polygon(95% 0, 100% 51%, 95% 100%, 5% 100%, 0 50%, 5% 0);

  padding: 5px 50px;
}

.IconsText p {
  font-size: 0.6em;
}
.IconsText h1 {
  font-size: 1em;
}
.Card_outer {
  position: relative;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Card_main {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -80px;
}
.Card_main .Card_item {
  padding: 4rem;
  width: 300px;
  color: #fff;
  line-height: 1.5em;
}
.Card_main .Card_item h3 {
  font-size: 1.3em;
  margin-bottom: 0.6em;
  font-weight: 500;
}
.Card_main .Card_item p {
  font-weight: 100;
}
.Card_main .Card_item:first-child {
  background: #2774d2;
}
.Card_main .Card_item:nth-child(2n) {
  background: #1de470;
}
.Card_main .Card_item:nth-child(3n) {
  background: #f26225;
}
.About_mission {
  padding: 1rem 8vw;
  margin-bottom: 6em;
}
.About_mission p {
  font-size: 1.2em;
  font-weight: 300;
  text-align: justify;
}
.About_mission h3 {
  font-weight: 500;
  margin-bottom: 0.4em;
}

@media only screen and (max-width: 768px) {
  .About_header {
    flex-direction: column-reverse;
    padding: 1rem 4vw;
  }
  .About_header_title {
    font-size: 1.5em;
  }
  .About_mission h3 {
    font-size: 1.5em;
  }
  .Card_outer {
    position: static;
    height: max-content;
  }
  .Card_main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: static;
  }
  .Card_main .Card_item h3 {
    font-size: 1.5em;
  }
  .Card_main .Card_item p {
    line-height: 1.2em;
    letter-spacing: 0.05em;
    font-size: 1.3em;
    opacity: 0.8;
  }
  .Card_main .Card_item {
    padding: 3rem 2rem;
    width: 100vw;
    color: #fff;
    line-height: 1.5em;
  }
}

@media only screen and (min-width: 1000px) {
  .About_header_right img {
    max-height: 27em;
  }
  .About_header_left p {
    font-size: 1.5em;
  }
}
