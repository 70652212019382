/* scrrolbar animaton */
/* ::-webkit-scrollbar {
  width: 12px;
}
::-webkit-scrollbar-track {
  background: white;
}
::-webkit-scrollbar-thumb {
  background: var(--purple);
  border: 2px white solid;
  border-radius: 100vh;
}  */

.App {
  background: var(--secondary);
}
.App header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 10vh;
}
.App header p {
  font-size: 7vh;
  color: var(--text);
  text-transform: uppercase;
  text-shadow: 2px 2px 5px var(--primary);
  font-family: 'Times New Roman', Times, serif;
}
.Logo {
  height: 10vw;
}
