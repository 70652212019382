@import url('https://fonts.googleapis.com/css2?family=Orbitron&family=Varela+Round&display=swap');

.HideNav {
  transform: translateY(-150px);
  transition: transform 1s ease;
}
.hide {
  display: none;
}
.Main {
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  padding: 0 5vw;
  width: 100vw;
  position: sticky;
  top: -1px;
  z-index: 100;
  font-size: 22px;
  transition: transform 0.7s linear;
  margin-top: 30px;
}
.Left img {
  height: 1.8em;
}
.Center {
  display: flex;
  align-items: center;
  padding-left: 2vw;
}
.Center a {
  text-decoration: none;
  color: rgb(72 72 72);
  padding: 10px;
  font-weight: 600;
  font-size: 0.8em;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  display: inline-block;
}
.Center p,
.Center span {
  display: inline-block;
  padding: 10px;
}
.Center a:hover {
  /* color: var(--secondary); */
  transform: translateY(-4px);
  transition-duration: 0.3s;
}
.Center a:focus {
  opacity: 0.9;
  /* border-bottom: 1px solid var(--secondary); */
}
.NavActive {
  color: var(--purple) !important;
  border-bottom: 1px solid var(--purple);
  transition-delay: 0.1s;
}

.Courses_main {
  position: relative;
}
.Courses_main:hover .Courses_dropdown {
  height: 310px;
  visibility: visible;
  opacity: 1;
}
.Courses_dropdown {
  font-size: 0.9em;
  transition: height 0.4s cubic-bezier(0.65, 0, 0.076, 1),
    opacity 0.5s cubic-bezier(0.65, 0, 0.076, 1);
  height: 0px;
  position: absolute;
  top: 67px;
  width: max-content;
  background: #fff;
  box-shadow: 0px 4px 0px var(--red);
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.Courses_dropdown > a {
  transform: none !important;
  color: black;
  padding: 5px;
  margin: 0;
  text-transform: capitalize;
}
.Courses_dropdown > a:hover {
  background: rgb(164, 158, 221);
}
.Contact_main {
  position: relative;
}
.Contact_main:hover .Contact_dropdown {
  height: 65px;
  visibility: visible;
  opacity: 1;
}
.Contact_dropdown {
  font-size: 0.9em;
  transition: height 0.4s cubic-bezier(0.65, 0, 0.076, 1), opacity 0.5s;
  height: 0px;
  position: absolute;
  top: 67px;
  background: #fff;
  box-shadow: 0px 4px 0px var(--red);
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  flex-direction: column;
  width: 90%;
  text-align: center;
}
.Contact_dropdown > a {
  transform: none !important;
  color: black;
  padding: 5px;
  margin: 0;
  text-transform: capitalize;
}
.Contact_dropdown > a:hover {
  background: rgb(164, 158, 221);
}
/* certificate download button */

.Download_button {
  position: relative;
  border-width: 0;
  color: white;
  font-size: 15px;
  font-weight: 600;
  border-radius: 4px;
  z-index: 1;
  cursor: pointer;
}
.Download_button:hover {
  filter: brightness(0.8);
}

.Download_button .Docs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  min-height: 40px;
  padding: 0 10px;
  border-radius: 4px;
  z-index: 1;
  color: var(--purple);
  background-color: white;
  border: 1px solid var(--purple);
  transition: all 0.3s;
  /* background: white; */
  /* color: var(--purple); */
}

.Admin_button {
  position: relative;
  border-width: 0;
  background: var(--purple) !important;
  color: white;
  font-size: 15px;
  font-weight: 600;
  border-radius: 4px;
  z-index: 1;
  cursor: pointer;
}
.Admin_button:hover {
  filter: brightness(0.8);
}

.Admin_button .Icon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  min-height: 40px;
  padding: 0 10px;
  border-radius: 4px;
  z-index: 1;
  color: white;
  border: 1px solid var(--purple);
  transition: all 0.3s;
}
.Hamburger {
  display: none;
}

/* media queries for tablet device */
@media only screen and (max-width: 768px) {
  .Main_mobile {
    height: 2.1em;
    overflow: hidden;
    transition: height 0.5s;
    position: sticky;
    top: 0;
  }
  .Main {
    /* background-color: var(--purple); */
    flex-direction: column;
    color: var(--purple);
    overflow: hidden;
    margin-top: 15px;
    transition: height 0.5s;
    padding: 5px;
  }
  .Hamburger {
    display: inline-block;
  }
  .Hamburger span {
    display: inline-block;
    position: absolute;
    border-radius: 50px;
    width: 1.7em;
    height: 3px;
    background: var(--purple);
  }
  .Ham1 {
    top: 0.55em;
    right: 0.9em;
  }
  .Ham2 {
    top: 0.95em;
    right: 0.9em;
  }
  .Ham3 {
    top: 1.35em;
    right: 0.9em;
  }
  .Center {
    flex-direction: column;
    margin-top: 30px;
  }
  .Left img {
    background: white;
    border-radius: 100%;
    position: absolute;
    left: 0.9em;
  }
  .Center a {
    color: var(--purple);
  }
  .NavActive {
    color: var(--purple) !important;
    border-bottom: none;
    transition-delay: 0.1s;
  }
  .Download_button .Docs {
    color: var(--purple);
    background: #fff;
    border: 1px solid var(--purple);
  }
  .Download_button {
    margin-bottom: 2em;
  }
  .Center {
    padding: 0;
  }
  .Center p,
  .Center span {
    text-align: center;
  }
  .Center a {
    text-align: center;
  }
  .Courses_main,
  .Contact_main {
    position: static;
  }
  .Courses_dropdown {
    position: static;
  }
  .Contact_dropdown {
    display: none;
  }
}
