.certiificate_page_main {
  /* display: flex; */
}
.Certificate_page_form {
  width: 300px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px auto;
  border-radius: 21px;
  background: #f3f2f2;
  box-shadow: 20px 20px 60px #efdcdc, -20px -20px 60px #ffffff;
}
.download_button {
  margin: 30px;
}
.certificate_page_title {
  font-weight: 700;
  font-size: 1.3em;
  opacity: 0.5;
  margin: 1em;
  text-align: center;
}
.Download_button {
  position: relative;
  border-width: 0;
  color: white;
  font-size: 15px;
  font-weight: 600;
  border-radius: 4px;
  z-index: 1;
}

.Download_button .Docs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  min-height: 40px;
  padding: 0 10px;
  border-radius: 4px;
  z-index: 1;
  background-color: var(--purple);
  color: white;
  /* background: white; */
  /* color: var(--purple); */
  /* border: 1px solid white; */
  transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
}

.Download {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 90%;
  margin: 0 auto;
  z-index: -1;
  border-radius: 4px;
  transform: translateY(0%);
  background-color: #01e056;
  border: solid 1px #01e0572d;
  transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
}

.Download_button:focus .Download {
  transform: translateY(100%);
}

.Download svg polyline,
.Download svg line {
  animation: docs 1s infinite;
}

@keyframes docs {
  0% {
    transform: translateY(0%);
  }

  50% {
    transform: translateY(-15%);
  }

  100% {
    transform: translateY(0%);
  }
}

/* loader */
.loader {
  padding-bottom: 1em;
}
.scanner h1 {
  color: transparent;
  font-size: 1.4rem;
  position: relative;
  overflow: hidden;
  width: fit-content;
}

.scanner h1::before {
  content: 'Verifing...';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  border-right: 3px solid var(--red);
  overflow: hidden;
  color: black;
  animation: load91371 2s linear infinite;
}

@keyframes load91371 {
  0%,
  10%,
  100% {
    width: 0;
  }

  10%,
  20%,
  30%,
  40%,
  50%,
  60%,
  70%,
  80%,
  90%,
  100% {
    border-right-color: transparent;
  }

  11%,
  21%,
  31%,
  41%,
  51%,
  61%,
  71%,
  81%,
  91% {
    border-right-color: black;
  }

  60%,
  80% {
    width: 100%;
  }
}
/* certificate */
.Certificate_cont_wrapper {
  overflow-x: auto;
}
#Certificate_cont {
  overflow-x: hidden;
  margin: 20px auto;
  margin-bottom: 40px;
  /* width: 210mm; */
  /* width: 297mm; */
  /* height: 297mm; */
  /* height: 210mm; */
  max-width: 900px;
  aspect-ratio: 11.7/8.3;
  border: 5px double #4e3524;
  font-size: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
}
.Certificate_top_svg_wrapper {
  position: relative;
  width: 100%;
}
.Certificate_top_svg_wrapper #wave {
  transform: rotate(180deg);
}
.Certificate_top_svg_wrapper .Iso_img {
  position: absolute;
  left: 5em;
  bottom: -4em;
  height: 10em;
  z-index: 10;
}
.Certificate_title_cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  text-transform: capitalize;
  width: fit-content;
}
.Certificate_title_cont h1 {
  position: relative;
  z-index: 20;
  font-size: 5em;
  font-family: 'Roboto Slab', serif;
  margin-right: 5px;
}
.Certificate_title_cont h2 {
  font-size: 4em;
  margin: 0 5px;
  font-weight: 300;
  color: rgb(156, 51, 3);
}
.Certificate_title_cont p {
  margin-left: 5px;
  margin-top: 15px;
  font-family: 'Libre Baskerville', serif;
  font-size: 3em;
  font-weight: 600;
}
.Certificate_title_cont_upper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Certificate_title_cont_bottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* opacity: 0.8; */
}

.Certificate_title_cont_bottom h3 {
  font-size: 2em;
}
.Certificate_title_cont_bottom h4 {
  font-size: 1.5em;
  margin-top: 2mm;
  font-weight: 500;
}
.CertificateTextFrame {
  position: relative;
  width: 100%;
  font-size: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
}
.CertificateTextFrame img {
  width: 20%;
  display: block;
  margin: auto;
}
.CertificateText {
  position: absolute;
  left: 50%;
  top: 46%;
  transform: translate(-50%, -50%);
  font-size: inherit;
  color: #4e3524;
  font-weight: 500;
}
.Certificate_bottom_main {
  margin-top: -30px;
  font-style: italic;
  padding: 4mm 10mm;
  line-height: 1.8;
  font-weight: 500;
}
.Certificate_bottom_main p {
  opacity: 1;
  color: black;
  font-size: 1.7em;
  text-align: justify;
}
.Certificate_footer_wrapper {
  width: 100%;
  margin-bottom: 10mm;
}
.Certificate_footer {
  color: #4e3524;
  font-size: 1.5em;
  font-weight: 700;
  display: grid;
  margin: auto;
  place-content: center;
  grid-template-columns: 1fr 1fr;
  gap: 10mm;
  padding: 0 10mm;
  line-height: 2;
}
.Certificate_footer_grade {
  font-size: 1.3em;
  padding: 0 10mm;
  text-align: center;
  margin: 0 auto;
  margin-top: 3mm;
  color: rgba(0, 0, 0, 0.63);
  opacity: 1;
  font-weight: 600;
}

/* form inputs */
.Certificate_page_main form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background: #bebebe; */
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.serial_no_input {
  -moz-appearance: textfield;
}

.serial_no_input-group {
  position: relative;
}

.serial_no_input {
  border: solid 2px #bebebe;
  border-radius: 1em;
  background: transparent;
  padding: 0.5em 1rem;
  text-align: center;
  font-size: 1.3rem;
  font-weight: 700;
  width: 200px;
  color: rgb(53, 52, 52);
  transition: border 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
.input-group {
  position: relative;
}
.user-label {
  position: absolute;
  left: 15px;
  top: -3px;
  color: #636363;
  font-weight: 600;
  pointer-events: none;
  transform: translateY(1rem);
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.serial_no_input:focus,
.serial_no_input:valid {
  outline: none;
  border: 2px solid var(--purple);
}

.serial_no_input:focus ~ label,
.serial_no_input:valid ~ label {
  transform: translateY(-50%) scale(0.8);
  background-color: #f3f2f2;
  padding: 0 0.2em;
  color: var(--purple);
  font-weight: 500;
}

/* date picker */
.inputdate_label {
  font-size: 1.2em;
  font-weight: 600;
  /* color: var(--purple); */
  opacity: 0.8;
}
.Certificate_input_date {
  border-radius: 1em;
  /*border: 2px solid var(--purple); */
  font-size: 1.5em;
  border: none;
  outline: none;
  width: 240px;
  padding-top: 0.6em;
  background: transparent;
}
.Certificate_input_date::-webkit-datetime-edit {
  padding: 0 1em;
  border-radius: 5px;
}
.Certificate_input_date::-webkit-datetime-edit-fields-wrapper {
  padding: 1px;
}
.Certificate_input_date::-webkit-datetime-edit-text {
  color: gray;
  padding: 0 0.5em;
}
.Certificate_input_date::-webkit-datetime-edit-month-field {
  color: #363636;
}
.Certificate_input_date::-webkit-datetime-edit-day-field {
  color: #363636;
}
.Certificate_input_date::-webkit-datetime-edit-year-field {
  color: #363636;
}
.Certificate_input_date::-webkit-inner-spin-button {
  display: none;
}
.Certificate_input_date::-webkit-calendar-picker-indicator {
  font-size: 2rem;
  margin-right: 0.5em;
  filter: invert(0.5);
}
.Certificate_input_date::-webkit-calendar-picker-indicator:hover {
  filter: invert(0.1);
}

/* submit button */
.Form_submit_button {
  font-family: inherit;
  font-size: 20px;
  background: var(--purple);
  color: white;
  padding: 0.4em 1em;
  width: 200px;
  padding-left: 0.9em;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 16px;
  overflow: hidden;
  transition: all 0.2s;
  margin: 1em;
  cursor: pointer;
}

.Form_submit_button span {
  display: block;
  margin-left: -0.3em;
}

.Form_submit_button svg {
  visibility: hidden;
  transform-origin: center center;
  transition: transform 0.3s ease-in-out;
}

.Form_submit_button:active .svg-wrapper {
  animation: fly-1 0.1s ease-in-out infinite alternate;
}

.Form_submit_button:active svg {
  visibility: visible;
  transform: translateX(1.2em) rotate(45deg) scale(1.1);
}

.Form_submit_button:active span {
  transform: translateX(1.4em);
  visibility: hidden;
}

.Form_submit_button:active {
  transform: scale(0.95);
}
.certificate_errors {
  color: var(--red);
  margin-bottom: 1em;
  font-weight: 500;
  font-size: 1.1em;
}
@keyframes fly-1 {
  from {
    transform: translateY(0.1em);
  }

  to {
    transform: translateY(-0.1em);
  }
}

@media screen and (min-width: 650px) and (max-width: 770px) {
  #Certificate_cont {
    width: 90vw;
    /* height: 297mm; */
    font-size: 7px;
  }
  .download_button {
    /* display: none !important; */
  }
}

@media screen and (max-width: 650px) {
  #Certificate_cont {
    min-width: 650px;
    /* height: 297mm; */
    font-size: 6px;
  }
  .download_button {
    /* display: none !important; */
  }
}
