.main {
  background: rgb(232, 230, 253);
  position: sticky;
  top: 0;
  z-index: 999;
}

.container {
  max-width: 1200px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 25px;
  font-size: 18px;
  font-weight: 500;
}
.link {
  text-decoration: none;
  padding: 10px 15px;
  transition: background 0.5s;
  border-radius: 10px;
  color: var(--purple);
}

.link:hover {
  background: rgba(164, 158, 221, 0.24);
}
.logo {
  height: 1.8em;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.right button {
  background: var(--purple);
  color: white;
  padding: 10px 15px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: inherit;
  font-weight: inherit;
}
.right button:hover {
  filter: brightness(0.9);
}
