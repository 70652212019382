.MainCont {
  padding: 0px 5vw;
}
.FooterMain {
  height: auto;
  padding: 3vh 0;
  display: flex;
  justify-content: space-evenly;
}

.AddressText {
  display: flex;
  flex-direction: column;
  font-size: 1.2em;
  opacity: 0.7;
  margin: 2vh 0;
}
.AddressText a {
  text-decoration: none;
  color: var(--red);
}
.Left,
.Center,
.Right {
  margin: 0 10px;
  font-size: 1em;
}
.Center span,
.Right span {
  font-weight: 500;
  border-bottom: 2px solid var(--red);
  padding-bottom: 5px;
}
.Center div,
.Right div {
  margin: 15px 0;
}
.Center div a,
.Right div a {
  font-size: 1.2em;
  text-decoration: none;
  color: black;
  opacity: 0.7;
}
.Footer_Bottom_wraper {
  display: flex;
  justify-content: space-evenly;
  margin-top: 5vh;
}
.Center {
  margin-right: 5vw;
}
@media only screen and (max-width: 1035px) {
  .Footer_Bottom_wraper {
    display: flex;
    flex-direction: column;
  }
  .Center {
    margin-right: 0vw;
  }
}

/* Media query for tablet device */
@media only screen and (max-width: 768px) {
  .MainCont {
    padding: 0px 5vw;
  }
  .FooterMain {
    display: flex;
    flex-direction: column;
  }
  .Logo {
    height: 10vw;
  }

  .AddressText {
    display: flex;
    flex-direction: column;
    opacity: 0.7;
  }
  .AddressText {
    margin: 2vh 0;
  }
  .AddressText a {
    text-decoration: none;
    color: var(--red);
  }
  .Left,
  .Center,
  .Right {
    margin: 0 10px;
  }
  .Center span,
  .Right span {
    font-size: 1.5em;
    font-weight: 500;
    border-bottom: 2px solid var(--red);
    padding-bottom: 5px;
  }
  .Center div,
  .Right div {
    margin: 15px 0;
  }
  .Center div a,
  .Right div a {
    font-size: 1.2em;
    text-decoration: none;
    color: black;
    opacity: 0.7;
  }
  .Footer_Bottom_wraper {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
}
@media only screen and (max-width: 550px) {
  .MainCont {
    padding: 15px;
  }
  .FooterMain {
    height: auto;
    margin: 10px;
    margin-bottom: 20px;
    padding: 3vh 0;
    display: inline-block;
  }

  .AddressText {
    font-size: 1.3em;
    opacity: 0.7;
  }
  .Left,
  .Center,
  .Right {
    font-size: 1em;
  }
  .Center span,
  .Right span {
    font-size: 0.9em;
  }
  .Center div,
  .Right div {
    margin: 15px 0;
  }

  .Center div a,
  .Right div a {
    font-size: 0.9em;
    text-decoration: none;
    color: black;
    opacity: 0.7;
  }
  .Footer_Bottom_wraper {
    flex-direction: column;
    margin-top: 5vh;
  }
}
.DeveloperArea {
  text-align: center;
  font-size: 1em;
  text-decoration: none;
  color: black;
  opacity: 0.7;
  margin: 20px 5px;
  margin-bottom: 40px;
}
.DeveloperArea a {
  text-decoration: none;
  color: blue;
  font-weight: 600;
}
