.HomeMain {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 2em;
}
/* side navigation bar styles */
/* scrollbar animaton */
.SideBar::-webkit-scrollbar {
  width: 0px;
}

.SideBar {
  position: sticky;
  top: 0;
  right: 0px;
  width: 250px;
  background: white;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100vh;
  padding-top: 70px;
  font-size: 1.1em;
}

.InnerSideBar {
  display: flex;
  flex-direction: column;
}
.InnerSideBar h3 {
  color: var(--purple);
}
.InnerSideBar a {
  margin: 4px;
  text-decoration: none;
}

.Courses_Link a {
  padding: 5px 10px;
  color: rgb(32, 32, 32);
  text-decoration: none;
  display: inline-block;
}
.Courses_Link:hover {
  background: rgb(224, 223, 223);
}
.AddressText {
  opacity: 0.7;
}

/* course card container styles  */
.UpperMain {
  width: calc(100% - 350px);
  margin: 30px;
}
.CardMain {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.Card {
  margin: 2vw;
  width: 250px;
  height: 350px;
  background: #f0f0f0;
  border-radius: 10px;
  text-align: center;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  box-shadow: 0px 0px 2.2px rgba(0, 0, 0, 0.031),
    0px 0px 5.3px rgba(0, 0, 0, 0.044), 0px 0px 10px rgba(0, 0, 0, 0.055),
    0px 0px 17.9px rgba(0, 0, 0, 0.066), 0px 0px 33.4px rgba(0, 0, 0, 0.079),
    0px 0px 80px rgba(0, 0, 0, 0.11);
}

.Card:hover {
  box-shadow: 0px 0px 2.2px rgba(0, 0, 0, 0.065),
    0px 0px 5.3px rgba(0, 0, 0, 0.093), 0px 0px 10px rgba(0, 0, 0, 0.115),
    0px 0px 17.9px rgba(0, 0, 0, 0.137), 0px 0px 33.4px rgba(0, 0, 0, 0.165),
    0px 0px 80px rgba(0, 0, 0, 0.23);
  background: var(--orangeGradient);
}

.Card > .Blob {
  height: 10px;
  width: 75%;
  border-radius: 0 0 30px 30px;
  margin: 0 auto;
  background-color: var(--red);
  visibility: visible;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.Card:hover > .Blob {
  height: 0;
}

.Card > .Content > .Img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 30px auto 10px auto;
  width: 130px;
  height: 130px;
  background: linear-gradient(to right, #fbcd38, #fcbb5b);
  border-radius: 20px;
  font-size: 11px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.Content {
  position: relative;
}
.Card:hover > .Content > .Img {
  width: 100%;
  height: 220px;
  border-radius: 10px;
  margin: 0 auto;
  background-color: #f0f0f0;
  z-index: 99999;
  filter: contrast(0.6);
}

.Card > .CardTitle {
  padding: 15px 10px;
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
  z-index: -99;
  height: 130px;
}
.Card > .CardTitle > span {
  color: blue;
}
.Card > .CardTitle > h2 {
  margin-bottom: 7px;
  margin-top: 7px;
}

.Card > span {
  font-size: 24px;
}

.Card:hover > .CardTitle {
  opacity: 0;
  position: absolute;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.Card:hover > .CourseDetails {
  opacity: 1;
  visibility: visible;
}
.Card > .CourseDetails {
  opacity: 0;
  visibility: hidden;
  color: rgb(0, 119, 255);
  line-height: 30px;
  margin-top: 20px;
}
.Card > .CourseDetails a {
  text-decoration: none;
  color: rgb(0, 119, 255);
  border: 1px solid rgb(0, 119, 255);
  padding: 2px 8px;
  border-radius: 1em;
}

.Card > .Content > p {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  -ms-transform: translate(-50%, -50%);
  visibility: hidden;
  opacity: 0;
  transform: translate(-50%, -50%) scale(0, 0);
  -webkit-transition: all 0.75s;
  transition: all 0.75s;
  padding: 10px;
}

.Card:hover > .Content > p {
  position: absolute;
  visibility: visible;
  opacity: 1;
  transform: translate(-50%, -50%) scale(0.9, 1);
  -webkit-transition: all 0.1s;
  transition: all 0.1s, display 3s;
  backdrop-filter: blur(10px);
  border-radius: 20px;
}

.Card > .Content > p > .Description {
  color: black;
}
.Phone,
.Mail {
  display: flex;
  align-items: center;
}

.Testimonial_main {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  height: fit-content;
  font-size: 1.1em;
}
.Testimonial_inner {
  background: rgb(231 229 229);
  border-radius: 20px;
  margin: 20px 0;
  padding: 20px;
  padding-left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Testimonial_title {
  color: var(--yellow);
  text-align: center;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  width: 100%;
}
.Testimonial_title hr {
  /* transform: rotate(90deg); */
  width: 30%;
  height: 0px;
  border: 3px solid var(--primary);
  border-radius: 100%;
}
.Testimonial_title div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}
.Testimonial_inner .Testimonial_upper {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 500px;
  margin-right: 20px;
}

.Testimonial_inner .Testimonial_upper img {
  height: 100px;
  width: 100px;
  border-radius: 100%;
}
.Testimonial_inner .Testimonial_upper h1 {
  font-size: 1em;
  /* color: var(--orange); */
  color: var(--primary);
  text-align: center;
  padding-top: 10px;
}
.Testimonial_desc {
  background: whitesmoke;
  padding: 20px;
  border-radius: 20px;
}
.Testimonial_desc p {
  height: fit-content;
  padding: 0 50px;
  text-align: justify;
}
.Testimonial_desc .First_quote {
  margin-right: 20px;
  float: left;
  margin-top: -10px;
}
.Testimonial_desc .Last_quote {
  float: right;
  margin: 0;
  margin-top: -20px;
}
/* why to choose us? */
.Choose_us_main {
  color: rgb(27, 27, 27);
  border-top-right-radius: 100px;
  border-bottom-left-radius: 100px;
  margin-top: 100px;
}
.Choose_us_inner {
  display: flex;
  height: max-content;
}
.Choose_us_left {
  margin: 20px;
  padding: 0 60px;
}
.Choose_us_left h1 {
  font-size: 1.8em;
  margin-bottom: 1em;
}
.Choose_us_left_inner {
  font-size: 1.1em;
  display: flex;
}
.Choose_us_left .Choose_us_left_inner .Font_icon {
  display: flex;
  align-items: center;
  height: 70px;
  margin: 10px;

  margin-right: 30px;
  border-radius: 100%;
}
.Choose_us_right {
  width: 40%;
}
.Choose_us_right > img {
  height: 100%;
  width: 100%;
  border-top-right-radius: 100px;
}
/* Affiliations */
.Affiliation_main {
  margin-top: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Affiliation_main h1 {
  margin-bottom: 10px;
  color: rgb(43, 43, 43);
}
.Affiliation_img_cont {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Affiliation_img_1 {
  height: 130px;
  margin: 30px;
}
.Affiliation_img_2 {
  margin: 30px;
  height: 150px;
  width: 140px;
}

@media only screen and (max-width: 550px) {
  .SideBar {
    display: none;
  }
  .HomeMain {
    padding: 0em;
  }

  .UpperMain {
    width: 100%;
    margin: 0px;
  }
  .Testimonial_main {
    margin-top: 70px;
  }
  .Testimonial_inner {
    margin: 20px;
    padding: 5px;
    flex-direction: column;
  }
  .Testimonial_title hr {
    border: 2px solid var(--primary);
  }

  .Testimonial_inner .Testimonial_upper {
    width: auto;
  }
  .Testimonial_inner .Testimonial_upper img {
    height: 100px;
    width: 100px;
  }
  .Testimonial_inner .Testimonial_upper h1 {
    padding: 10px;
  }
  .Choose_us_main {
    border-radius: 15px;
    margin: 0 20px;
    margin-top: 100px;
  }
  .Choose_us_left {
    width: 100%;
    margin: 0px;
    padding: 15px;
  }
  .Choose_us_left h1 {
    font-size: 1.4em;
  }
  .Choose_us_inner {
    display: inline-block;
  }
  .Choose_us_left_inner {
    display: inline-block;
  }
  .Choose_us_left_inner p {
    text-align: justify;
    font-weight: 500;
  }
  .Choose_us_left .Choose_us_left_inner .Font_icon {
    width: 43px;
    height: 43px;
    background: none;
    margin-top: 10px;
    padding: 0px;
    border-radius: 100%;
  }
  .Choose_us_right {
    width: 100%;
    height: fit-content;
  }

  .Affiliation_main h1 {
    margin-bottom: 10px;
    color: rgb(43, 43, 43);
    font-size: 1.5em;
  }
  .Affiliation_img_cont {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Affiliation_img_1 {
    height: 130px;
    margin: 15px;
  }
  .Affiliation_img_2 {
    margin: 15px;
    height: 150px;
    width: 140px;
  }
  .Card {
    margin: 3vh 0;
  }
}

@media only screen and (max-width: 850px) {
  .HomeMain {
    padding: 0em;
  }

  .UpperMain {
    width: 100%;
    margin: 0px;
  }
  .Testimonial_main {
    margin-top: 70px;
  }
  .Testimonial_inner {
    margin: 20px;
    padding: 5px;
    flex-direction: column;
  }
  .Testimonial_title hr {
    border: 2px solid var(--primary);
  }

  .Testimonial_inner .Testimonial_upper {
    width: auto;
  }
  .Testimonial_inner .Testimonial_upper img {
    height: 100px;
    width: 100px;
  }
  .Testimonial_inner .Testimonial_upper h1 {
    padding: 10px;
  }
  .Choose_us_main {
    border-radius: 15px;
    margin: 0 20px;
    margin-top: 100px;
  }
  .Choose_us_left {
    width: 100%;
    margin: 0px;
    padding: 15px;
  }
  .Choose_us_left h1 {
    font-size: 1.4em;
  }
  .Choose_us_inner {
    display: inline-block;
  }
  .Choose_us_left_inner {
    display: inline-block;
  }
  .Choose_us_left_inner p {
    text-align: justify;
    font-weight: 500;
  }
  .Choose_us_left .Choose_us_left_inner .Font_icon {
    width: 43px;
    height: 43px;
    background: none;
    margin-top: 10px;
    padding: 0px;
    border-radius: 100%;
  }

  .Affiliation_main h1 {
    margin-bottom: 10px;
    color: rgb(43, 43, 43);
    font-size: 1.5em;
  }
  .Affiliation_img_cont {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Affiliation_img_1 {
    height: 130px;
    margin: 15px;
  }
  .Affiliation_img_2 {
    margin: 15px;
    height: 150px;
    width: 140px;
  }
  .Card {
    margin: 3vh 0;
  }
}
@media only screen and (max-width: 1150px) {
  .Choose_us_main {
    border-radius: 15px;
    margin: 0 20px;
    margin-top: 100px;
  }
  .Choose_us_left {
    width: 100%;
    margin: 0px;
    padding: 15px;
  }
  .Choose_us_left h1 {
    font-size: 1.4em;
  }
  .Choose_us_inner {
    display: inline-block;
  }
  .Choose_us_left_inner {
    display: inline-block;
  }
  .Choose_us_left_inner p {
    text-align: justify;
    font-weight: 500;
  }
  .Choose_us_left .Choose_us_left_inner .Font_icon {
    width: 43px;
    height: 43px;
    background: none;
    margin-top: 10px;
    padding: 0px;
    border-radius: 100%;
  }
}
