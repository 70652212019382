.hide {
  display: none;
}
.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: auto;
  margin: 20px 0;
}
.Left {
  margin-left: 5vw;
  font-size: 2.3vw;
  width: 50vw;
}

.TitleText {
  /* font-size: 1.09em; */
  font-family: 'Oleo Script', cursive;
  font-size: 1.7em;
  margin-bottom: 15px;
  color: var(--red);
  text-align: center;
}
.DescText {
  opacity: 0.7;
  font-weight: 600;
  font-size: 0.6em;
  padding-left: 10px;
}

.Avatar {
  height: 11vh;
}
.DesktopImg {
  width: 35vw;
  aspect-ratio: 16 / 9;
  clip-path: polygon(13% 0, 100% 0%, 100% 100%, 0% 100%);
}

/* media queries for  tablet device*/
@media only screen and (max-width: 770px) {
  .Header {
    margin: 5px 0;
  }
  .Left {
    margin-left: 5vw;
    font-size: 3vw;
  }
  .TitleText {
    width: 60vw;
  }
  .DescText {
    font-size: 0.7em;
  }
}
@media only screen and (max-width: 550px) {
  .Right {
    display: none;
  }
  .Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    height: auto;
    margin: 10px 0;
  }
  .Left {
    margin-left: 0;
    font-size: 1.1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
  }

  .TitleText {
    font-family: 'Oleo Script', cursive;
    font-size: 1.7em;
    margin-bottom: 10px;
    color: var(--red);
    text-align: center;
  }
  .DescText {
    opacity: 0.7;
    font-weight: 600;
    font-size: 0.8em;
  }
}
