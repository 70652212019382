.Active {
  background: rgb(164, 158, 221);
}
.Courses_main {
  line-height: 24px;
  display: flex;
  user-select: none;
}

.Courses_sidebar {
  background: #fff;
  position: sticky;
  top: 0px;
  right: 0px;
  width: 350px;
  border-left: 1px solid var(--purple);
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
  padding: 75px 0;
  width: 270px;
  flex-shrink: 0;
  z-index: 50;
}
.Courses_sidebar .Courses_Link:nth-child(odd) {
  background: #f3f2f2;
}
.Courses_sidebar .Courses_Link a {
  padding: 5px 20px;
  color: black;
  text-decoration: none;
  display: inline-block;
  width: 270px;
}
.Courses_sidebar .Courses_Link:hover {
  background: rgb(164, 158, 221);
}
.Courses_right {
  padding-top: 50px;
  margin: 70px 40px;
  width: calc(100vw - 350px);
}
.Upper_main {
  display: flex;
}
.Upper_main_left h1 {
  line-height: 1em;
  color: rgb(87, 119, 186);
  margin-bottom: 20px;
}
.Upper_main_left h2 {
  color: var(--grey);
  font-weight: 400;
  line-height: 30px;
}
.Upper_main_right img {
  width: 35vw;
}
/* Contact button */
.Contact_btn_main {
  padding: 20px 50px;
  /* float: right; */
}
.Anchor_tag {
  background-color: transparent;
  border: 2px solid rgb(87, 119, 186);
  border-radius: 10px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  letter-spacing: 0.05em;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 10px 20px;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  transition: all 0.3s ease-in-out;
  user-select: none;
  font-size: 13px;
}

.Anchor_tag::before {
  content: ' ';
  width: 1.5625rem;
  height: 2px;
  background: rgb(87, 119, 186);
  top: 50%;
  left: 1.5em;
  position: absolute;
  transform: translateY(-50%);
  transform-origin: center;
  transition: background 0.3s linear, width 0.3s linear;
}

.Anchor_tag .Anchor_text {
  font-size: 1.125em;
  line-height: 1.33333em;
  padding-left: 2em;
  display: block;
  text-align: left;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
  text-decoration: none;
  color: rgb(87, 119, 186);
}

.Anchor_tag .Top_key {
  height: 2px;
  width: 1.5625rem;
  top: -2px;
  left: 0.625rem;
  position: absolute;
  background: #e8e8e8;
  transition: width 0.5s ease-out, left 0.3s ease-out;
}

.Anchor_tag .Bottom_key_1 {
  height: 2px;
  width: 1.5625rem;
  right: 1.875rem;
  bottom: -2px;
  position: absolute;
  background: #e8e8e8;
  transition: width 0.5s ease-out, right 0.3s ease-out;
}

.Anchor_tag .Bottom_key_2 {
  height: 2px;
  width: 0.625rem;
  right: 0.625rem;
  bottom: -2px;
  position: absolute;
  background: #e8e8e8;
  transition: width 0.5s ease-out, right 0.3s ease-out;
}

.Anchor_tag:hover {
  color: white;
  background: rgb(87, 119, 186);
}

.Anchor_tag:hover::before {
  width: 0.9375rem;
  background: white;
}

.Anchor_tag:hover .Anchor_text {
  color: white;
  padding-left: 1.5em;
}

.Anchor_tag:hover .Top_key {
  left: -2px;
  width: 0px;
}

.Anchor_tag:hover .Bottom_key_1,
.Anchor_tag:hover .Bottom_key_2 {
  right: 0;
  width: 0;
}
/* topics container */
.Topic_title {
  color: rgb(87, 119, 186);
  text-align: center;
  padding-top: 100px;
  margin-bottom: 50px;
  margin-left: -7vw;
}
.Topic_main_cont {
  padding-bottom: 100px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  column-gap: 40px;
  row-gap: 20px;
  padding: 0 30px;
}
.Topic_main {
  text-align: justify;
  display: flex;
}
.Topic_main_left {
  margin-right: 10px;
}
.Topic_main_right h3 {
  color: rgba(0, 0, 0, 0.753);
  margin-bottom: 10px;
}
.Topic_main_right p {
  opacity: 0.7;
  font-size: 1.1em;
}
.Criteria_table {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 10px;
  border-radius: 10px;
  margin-top: 50px;
  user-select: text;
}
.Criteria_table .Criteria_table_inner {
  background: #dddbdb;
  border-radius: 10px;
  padding: 10px;
}
.Criteria_table .Criteria_table_inner h4 {
  color: rgba(0, 0, 0, 0.795);
  font-size: 1.1em;
  margin-bottom: 5px;
}
.Criteria_table .Criteria_table_inner p {
  color: rgba(0, 0, 0, 0.795);
  /* opacity: 0.95; */
  font-weight: 500;
  padding-left: 10px;
}

/* in brief container */
.Breif_main_cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 60px 0;
}
.Brief_img_cont img {
  height: 200px;
}
.Brief_title {
  color: rgb(87, 119, 186);
  margin-bottom: 20px;
  padding-left: 50px;
  line-height: 30px;
}
.Brief_description_cont p {
  color: var(--grey);
  text-align: justify;
  font-size: 1.1em;
}
.Brief_description_cont ul li {
  list-style-position: inside;
}

/* Relevant courses styles */
.Relevant_cont_title {
  margin: 30px;
  text-align: center;
  font-size: 1.5em;
  opacity: 0.7;
}
.Relevant_courses_cont {
  color: black;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  height: 368px;
  overflow: hidden;
}
.Relevant_box {
  margin: 1em;
  margin-top: 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 200px;
  height: 330px;
  border-radius: 10px;
  box-shadow: 0px 0px 30px #bebebe, -15px -15px 30px #ffffff;
  font-size: 12px;
}
.Relevant_img {
  width: 100%;
  height: calc(100% - 210px) !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.Relevant_bottom {
  padding: 15px;
}
.Relevant_bottom h2 {
  color: var(--orange);
  text-align: center;
}
.Relevant_icons {
  display: flex;
  justify-content: space-between;
  margin: 10px;
}
.Relevant_icons > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Relevant_description {
  text-align: justify;
  line-height: 21px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.3em;
}
.Relevant_link {
  background: var(--orangeGradient);
  outline: none;
  border: none;
  border-radius: 10px;
  padding: 6px 16px;
  display: block;
  margin: auto;
  margin-top: 10px;
}
.Relevant_link a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.671);
}
/* Enspirational quotes */
.Quotes_main_cont {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px 0;
}
.Quote_card1 {
  width: 600px;
  height: 125px;
  /* background: rgb(183, 226, 25); */
  font-family: inherit;
  position: relative;
  background: rgba(201, 233, 85, 0.459);
  border-radius: 10px;
  border-bottom-left-radius: 70px;
  border-top-right-radius: 70px;
  box-shadow: 0px 0px 30px rgba(201, 233, 85, 0.459);
}

.Quote_text1 {
  color: var(--red);
  opacity: 0.5;
  padding-left: 30px;
  position: relative;
  transform: scale(0.6);
  position: absolute;
}

.Quote_body_text1 {
  text-align: center;
  font-size: 1.3em;
  font-weight: 900;
  padding: 60px 40px;
  color: #465512;
  position: absolute;
  top: -30px;
  left: 3em;
  width: 80%;
}

.Quote_author1 {
  margin-top: 5px;
  transition: 0.5s;
  position: absolute;
  bottom: 3px;
  right: 5px;
  font-weight: 700;
  color: rgb(158, 196, 21);
  padding-left: 30px;
}

@media only screen and (max-width: 768px) {
  .Courses_sidebar {
    display: none;
  }
  .Courses_right {
    padding: 0 1em;
    padding-top: 50px;
    margin: 40px 0;
    width: 100%;
  }
  .Upper_main {
    flex-direction: column;
  }
  .Upper_main_left h1 {
    line-height: 1em;
  }
  .Upper_main_right img {
    width: 30vw;
    float: right;
  }
  .Upper_main_left h2 {
    text-align: justify;
    opacity: 1;
    color: #000;
  }
  .Criteria_table {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
    gap: 10px;
    border-radius: 10px;
    margin-top: 50px;
  }
  .Topic_main_right p {
    opacity: 0.9;
  }
  .Breif_main_cont {
    flex-direction: column;
    justify-content: center;
    margin: 60px 0;
  }
  .Brief_img_cont img {
    height: 11em;
  }
  .Breif_main_cont:nth-child(even) {
    flex-direction: column-reverse;
  }
  .Brief_title {
    color: rgb(87, 119, 186);
    margin-bottom: 20px;
    padding: 0;
    text-align: center;
    line-height: 30px;
  }
  .Relevant_description {
    text-align: center;
  }
  .Brief_description_cont p {
    color: #000;
  }
  .Relevant_courses_cont {
    height: 368px;
  }
  .Quote_card1 {
    height: 140px;
    width: 90vw;
  }
  .Quote_text1 {
    padding-left: 0px;
    transform: scale(0.4);
  }
  .Quote_body_text1 {
    font-weight: 900;
    padding: 60px 0px;
    color: #465512;
    position: absolute;
    top: -30px;
    left: 2.8em;
  }
  .Quote_author1 {
    margin-top: 5px;
    transition: 0.5s;
    position: absolute;
    font-size: 1.2em;
    bottom: 10px;
    right: 10px;
  }
}

/*  */

@media only screen and (max-width: 500px) {
  .Upper_main {
    flex-direction: column-reverse;
  }
  .Courses_right {
    padding: 0 1em;
    padding-top: 0px;
  }
  .Upper_main_right img {
    width: 50vw;
    float: right;
  }
  .Quote_card1 {
    height: 165px;
  }
  .Quote_body_text1 {
    width: 70%;
  }
}
