.Contact_form_title {
  text-align: center;
  color: rgb(78, 78, 78);

  margin-top: 50px;
}
.Contact_main_cont {
  display: grid;
  align-content: center;
  justify-content: center;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  width: 70vw;
  position: relative;
  margin: 50px auto;
}

.Contact_form_left {
  width: 100%;
}

.Contact_form_left img {
  /* height: 100%; */
  width: 100%;
}
.Contact_form_right h3 {
  color: var(--purple);
  font-size: 1.8em;
  margin: 0.5em;
}
.Contact_form_right {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: clamp(300px, 100%, 400px);
}
.Contact_form_right input {
  background: #f3f3f3;
  color: #000;
  font-weight: 500;
  padding: 1em;
  margin: 0.5em;
  font-size: 1.3rem;
  border: none;
  text-transform: capitalize;
  outline: none;
}
.Contact_form_right input::placeholder {
  text-transform: capitalize;
}
.Contact_username,
.Contact_subject {
  border: none;
}
.Contact_message {
  height: clamp(100px, 100%, 300px);
  overflow-y: scroll;
  resize: vertical;
}
.Contact_submit_btn {
  background: var(--purple);
  color: #fff;
  font-size: 1.5em;
  border-radius: 10px;
  padding: 0.3em 0.6em;
  width: fit-content;
  margin: 0.6em;
  margin-left: calc(0.6em + 5px);
  border: none;
  cursor: pointer;
}
.Contact_submit_btn:disabled {
  filter: blur(2px);
}
/*  */
.Contact_bottom_main {
  display: grid;
  align-content: center;
  justify-content: center;
  grid-template-columns: 1fr 1fr;

  position: relative;
  border-radius: 20px;
  width: 80vw;
  height: fit-content;
  margin: 70px auto;
  font-size: 1.1em;
}
.Contact_form_left_title {
  color: var(--purple);
  font: 900;

  font-size: 1.5em;
  margin-bottom: 20px;
}
.Contact_bottom_right {
  width: 390px;
  background: #fff;
  padding-left: 20px;
  margin: auto;
}
.Contact_bottom_right p {
  color: rgb(73, 72, 72);
  font-size: 1.1em;
  text-align: justify;
  font-weight: 500;
}

.Contact_form_address {
  display: flex;
  align-items: center;
  margin-top: 2em;
}
.Contact_form_address p {
  padding-left: 2em;
}
.Contact_form_address p a {
  text-decoration: none;
  opacity: 0.8;
  color: inherit;
}
.Google_map {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  width: clamp(400px, 100%, 50vw);
  height: 500px;
  border: none;
}

@media only screen and (max-width: 768px) {
  .Contact_main_cont {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    width: 95vw;
  }
  .Contact_bottom_main {
    display: flex;
    flex-direction: column-reverse;
    align-content: center;
    justify-content: center;
    width: 100vw;
  }
  .Contact_bottom_right {
    width: 90%;
    background: #fff;
    padding: 0;
    margin: auto;
  }
  .Contact_form_address p {
    margin-left: 0.5em;
    padding: 0;
  }
  .Google_map {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    width: 100vw;
  }
}
