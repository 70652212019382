.main {
  margin: 25px;
  margin-top: 0px;
  display: none;
  max-width: 768px;
  min-height: calc(100vh - 50px);
}

.mobile_text {
  margin: 250px 25px;
  color: var(--red);
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}

.container {
  margin: auto;
  /* background: #dddddd6e; */
  padding: 20px;
  border-radius: 20px;
  box-shadow: 5px 5px 25px #00000021;
}

.container h2 {
  color: var(--purple);
}

.container .error {
  color: var(--red);
  font-weight: 600;
  text-transform: capitalize;
}

.container form {
  margin-top: 40px;
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 35px;
  row-gap: 25px;
}
.container form div {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.container form label {
  font-size: 17px;
  color: rgba(0, 0, 0, 0.849);
  font-weight: 600;
}
.container form .input {
  min-width: 300px;
  border-radius: 8px;
  outline: none;
  border: 1px solid #00000042;
  font-size: 17px;
  padding: 13px;
}
.container form .input::placeholder {
  opacity: 0.6;
}
.container form input:focus {
  outline: blue;
  outline-width: 1px;
}
.container form button {
  height: fit-content;
  align-self: self-end;
  min-width: 300px;
  border-radius: 8px;
  outline: none;
  border: none;
  font-size: 17px;
  padding: 13px;
  color: white;
  background: var(--purple);
  transition: filter 300ms;
}
.container form button:hover {
  filter: brightness(0.9);
}
@media only screen and (min-width: 550px) {
  .mobile_text {
    display: none;
  }
  .main {
    display: block;
    margin-right: auto;
    margin-left: auto;
    margin-top: 20px;
    padding: 25px;
  }
}
@media only screen and (min-width: 768px) {
  .container form {
    grid-template-columns: 1fr 1fr;
  }
}
