* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;

  /* --secondary: rgb(199, 199, 199); */
  --secondary: rgb(255, 255, 255);
  --primary: #169962;
  --text: #c65032;
  --purple: #6d28d9;
  --orange: #ff8500;
  --yellow: #fbcd38;
  --red: #ff193b;
  --grey: #515f7d;
  --orangeGradient: linear-gradient(to right, #fbcd38, #fcbb5b);
}
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  overflow-x: hidden;
}
