.main {
  margin: 25px;
  margin-top: 0px;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  gap: 50px;
  justify-content: space-evenly;
  max-width: 1200px;
}
.left {
  width: 70vw;
  margin: auto;
}
.right {
  margin: auto;
  /* background: #dddddd6e; */
  padding: 20px;
  border-radius: 20px;
  box-shadow: 5px 5px 25px #00000021;
}

.right h2 {
  color: var(--purple);
}

.right .error {
  color: var(--red);
  font-weight: 600;
  text-transform: capitalize;
}

.right form {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.right form div {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.right form span {
  display: flex;
  gap: 10px;
}

.right form span input {
  /* width: 5px; */
}

.right form span label {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.486);
  font-weight: 600;
}
.right form label {
  font-size: 17px;
  color: rgba(0, 0, 0, 0.849);
  font-weight: 600;
}
.right form .input {
  min-width: 300px;
  border-radius: 8px;
  outline: none;
  border: 1px solid #00000042;
  font-size: 17px;
  padding: 13px;
}
.right form .input::placeholder {
  opacity: 0.6;
}
.right form input:focus {
  outline: blue;
  outline-width: 1px;
}
.right form button {
  min-width: 300px;
  border-radius: 8px;
  outline: none;
  border: none;
  font-size: 17px;
  padding: 13px;
  color: white;
  background: var(--purple);
  transition: filter 300ms;
}
.right form button:hover {
  filter: brightness(0.9);
}

@media only screen and (min-width: 550px) {
  .main {
    margin-right: auto;
    margin-left: auto;
    margin-top: 40px;
    grid-template-columns: 1fr 1fr;
  }
  .left {
    max-width: 500px;
  }
}
