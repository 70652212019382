.main {
  margin: 25px;
  margin-top: 0px;
  display: none;
  max-width: 1200px;
  min-height: calc(100vh - 50px);
}

.mobile_text {
  margin: 250px 25px;
  color: var(--red);
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}

.warn_text {
  text-align: center;
  font-size: 1.8em;
  color: rgba(0, 0, 0, 0.637);
  margin-top: 140px;
}
.upper_content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  gap: 50px;
  align-items: baseline;
  min-width: 550px;
}
.upper_content h2 {
  color: var(--purple);
  text-decoration: underline;
}
.table_wrapper {
  margin: auto;
  overflow-x: auto;
  width: fit-content;
}

.upper_content button {
  position: relative;
  border-width: 0;
  color: var(--purple) !important;
  background: white;
  font-size: 15px;
  font-weight: 600;
  border-radius: 4px;
  z-index: 1;
  cursor: pointer;
}
.upper_content button .Icon:hover {
  background: rgba(0, 0, 0, 0.11) 000;
}

.upper_content button .Icon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  min-height: 40px;
  padding: 0 10px;
  border-radius: 4px;
  z-index: 1;
  color: var(--purple);
  border: 1px solid var(--purple);
  transition: all 0.3s;
}
table {
  padding: 0px;
  border-radius: 10px;
  overflow-x: hidden;
  border-spacing: 1px;
  white-space: nowrap;
}
table thead {
  background: var(--purple);
}
table thead tr th {
  color: white;
  padding: 10px 15px;
  text-align: left;
}
table tbody {
  background: #ddd;
}
table tbody tr {
  transition: background 0.3s;
}
table tbody tr:nth-child(odd) {
  background: #eeecec;
}

table tbody tr td {
  padding: 10px 15px;
  text-align: left;
}
table tbody tr .del {
  transition: background 0.3s;
  cursor: pointer;
}
table tbody tr .del:hover {
  background: #00000028;
}

@media only screen and (min-width: 550px) {
  .mobile_text {
    display: none;
  }
  .main {
    display: block;
    margin-right: auto;
    margin-left: auto;
    max-width: 1200px;
    padding: 25px;
  }
}
