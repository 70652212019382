.main {
  z-index: 50;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.692);
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  align-items: center;
  justify-content: center;
}
.main span {
  display: inline-block;
  height: 100px;
  aspect-ratio: 1/1;
  border-radius: 100%;
  border-bottom: 3px solid white;
  animation: spin 0.7s infinite linear;
}
.main p {
  color: rgba(255, 255, 255, 0.685);
  font-size: 20px;
  letter-spacing: 0.1em;
}

@keyframes spin {
  0% {
    transform: rotate(360deg);
  }
  70% {
    border-color: var(--purple);
  }
}
